import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import { FaPhoneAlt } from "react-icons/fa";
import { Link } from "react-router-dom";

import img1 from "../../img/component_images/about_us_03_scaled.jpg";
import img2 from "../../img/component_images/about_us_trust_scaled.jpg";

import "./style.css";

const Opening = () => {
  const { t } = useTranslation();

  const onClickLinkTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <section className=" opening_section">
        <Container>
          <Row>
          <Col lg={6} className="opening_right">
              <div className="about-page-right opening_cont_right">
                <img src={img1} alt="about page" />
              </div>
            </Col>
            <Col lg={6} className="opening_left">
              <div className="about-page-left">
                <h4>Nytivatartás</h4>
                <Row>
                  <Col lg={12}>
                    <ul>
                      <li>Hétfő: 8:00 – 17:00</li>
                      <li>Kedd: 8:00 – 17:00</li>
                      <li>Szerda: 8:00 – 17:00</li>
                      <li>Csütörtök: 8:00 – 17:00</li>
                      <li>Péntek: 8:00 – 17:00</li>
                      <li>Szombat: ZÁRVA</li>
                      <li>Vasárnap: ZÁRVA</li>
                    </ul>
                  </Col>
                </Row>
                
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Opening;
