import {React} from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";

import { NavLink } from 'react-router-dom';

import { useEffect } from 'react';

/* Thumbnail images */

import img1 from "../../img/gallery/gallery_01_scaled.jpg";
import img2 from "../../img/gallery/gallery_02_scaled.jpg";
import img3 from "../../img/gallery/gallery_03_scaled.jpg";
import img4 from "../../img/gallery/gallery_04_scaled.jpg";
import img5 from "../../img/gallery/gallery_05_scaled.jpg";
import img6 from "../../img/gallery/gallery_06_scaled.jpg";

import img7 from "../../img/gallery/gallery_07_scaled.jpg";
import img8 from "../../img/gallery/gallery_08_scaled.jpg";
import img9 from "../../img/gallery/gallery_09_scaled.jpg";
import img10 from "../../img/gallery/gallery_10_scaled.jpg";
import img11 from "../../img/gallery/gallery_11_scaled.jpg";
import img12 from "../../img/gallery/gallery_12_scaled.jpg";

import img13 from "../../img/gallery/gallery_13_scaled.jpg";
import img14 from "../../img/gallery/gallery_14_scaled.jpg";

/* Full images */

import img1Full from "../../img/gallery/gallery_01.jpg";
import img2Full from "../../img/gallery/gallery_02.jpg";
import img3Full from "../../img/gallery/gallery_03.jpg";
import img4Full from "../../img/gallery/gallery_04.jpg";
import img5Full from "../../img/gallery/gallery_05.jpg";
import img6Full from "../../img/gallery/gallery_06.jpg";

import img7Full from "../../img/gallery/gallery_07.jpg";
import img8Full from "../../img/gallery/gallery_08.jpg";
import img9Full from "../../img/gallery/gallery_09.jpg";
import img10Full from "../../img/gallery/gallery_10.jpg";
import img11Full from "../../img/gallery/gallery_11.jpg";
import img12Full from "../../img/gallery/gallery_12.jpg";

import img13Full from "../../img/gallery/gallery_13.jpg";
import img14Full from "../../img/gallery/gallery_14.jpg";



import "./style.css";

import ReactFancyBox from 'react-fancybox/';
import 'react-fancybox/src/fancybox/fancybox.css';

const Gallery = () => {
  const { t } = useTranslation();

  const onClick = (e) => {
    e.preventDefault();
    console.log(e)
  };

/*   const render = () => {
    <ReactFancyBox image="../../img/gallery-1.jpg" />
  } */

/*   const shoot1 = () => {
    console.log("../../img/gallery/gallery_12_scaled.jpg");
    var htmlElement = "<div  class='overlay'><img src ='/static/media/gallery_01_scaled.68d7fa1d2f6eab1bd1e4.jpg'/></div>";
    document.body.innerHTML += htmlElement
  } */




  return (

    

    <section className="gauto-gallery-area section_70">
      <Container>
        <Row>
          {/* <Col lg={4} sm={6}>
            <ReactFancyBox
                thumbnail={img1}
                image={img1Full}/>
            
          </Col>
          <Col lg={4} sm={6}>
            <ReactFancyBox
                thumbnail={img2}
                image={img2Full}/>
          </Col> */}
          <Col lg={4} sm={6}>
            <ReactFancyBox
                thumbnail={img3}
                image={img3Full}/>

          </Col>
          <Col lg={4} sm={6}>
            <ReactFancyBox
                thumbnail={img4}
                image={img4Full}/>

          </Col>
          <Col lg={4} sm={6}>
            <ReactFancyBox
                thumbnail={img5}
                image={img5Full}/>

          </Col>
          <Col lg={4} sm={6}>
            <ReactFancyBox
                thumbnail={img6}
                image={img6Full}/>

          </Col>

          <Col lg={4} sm={6}>
            <ReactFancyBox
                thumbnail={img7}
                image={img7Full}/>

          </Col>
          <Col lg={4} sm={6}>
            <ReactFancyBox
                thumbnail={img8}
                image={img8Full}/>

          </Col>
          <Col lg={4} sm={6}>
            <ReactFancyBox
                thumbnail={img9}
                image={img9Full}/>

          </Col>
          <Col lg={4} sm={6}>
            <ReactFancyBox
                thumbnail={img10}
                image={img10Full}/>

          </Col>
          <Col lg={4} sm={6}>
            <ReactFancyBox
                thumbnail={img11}
                image={img11Full}/>

          </Col>
          <Col lg={4} sm={6}>
            <ReactFancyBox
                thumbnail={img12}
                image={img12Full}/>

          </Col>

        <Col lg={4} sm={6}>
            <ReactFancyBox
                thumbnail={img13}
                image={img13Full}/>

          </Col>
          <Col lg={4} sm={6}>
            <ReactFancyBox
                thumbnail={img14}
                image={img14Full}/>

          </Col>
        </Row>
        {/* <Row>
          <Col md={12}>
            <div className="load-more">
              <Link to="/" onClick={onClick} className="gauto-btn">
                {t("btn_gallery")}
              </Link>
            </div>
          </Col>
        </Row> */}
      </Container>
    </section>
  );
};

export default Gallery;
