import React from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";

import Homepage from "../HomePage";
import AboutPage from "../AboutPage";
import ServicePage from "../ServicePage";
import ServiceSingle from "../ServiceDetails";
import ServiceSingle2 from "../ServiceDetails2";
import ServiceSingle3 from "../ServiceDetails3";
import ServiceSingle4 from "../ServiceDetails4";
import ServiceSingle5 from "../ServiceDetails5";
import ServiceSingle6 from "../ServiceDetails6";
import ServiceSingle7 from "../ServiceDetails7";
import GalleryPage from "../GalleryPage";
import ErrorPage from "../ErrorPage";
import ContactPage from "../ContactPage";
import CareerPage from "../CareerPage";
import CareerPage2 from "../CareerPage2";
import Career from "../../components/Career";

const AllRoute = () => {
  return (
    <div>
      <Router>
        <Routes>
          <Route exact path="/" element={<Homepage />} />
          <Route path="/kezdolap" element={<Homepage />} />
          <Route path="/rolunk" element={<AboutPage />} />
          <Route path="/szolgaltatasaink" element={<ServicePage />} />
          <Route path="/kamionalvaz-egyengetes" element={<ServiceSingle />} />
          <Route path="/karosszeria-javitas" element={<ServiceSingle2 />} />
          <Route path="/autofenyezes" element={<ServiceSingle3 />} />
          <Route path="/biztositasi-ugyintezes" element={<ServiceSingle4 />} />
          <Route path="/automentes" element={<ServiceSingle5 />} />
          <Route path="/gepkocsi-berbeadas" element={<ServiceSingle6 />} />
          <Route path="/gepkocsi-tarolas" element={<ServiceSingle7 />} />
          <Route path="/galeria" element={<GalleryPage />} />
          <Route path="/error" element={<ErrorPage />} />
          <Route path="/kapcsolat" element={<ContactPage />} />
          <Route path="/karosszerialakatos" element={<CareerPage />} />
          <Route path="/szerelo" element={<CareerPage2 />} />
          <Route element={<ErrorPage />} />
        </Routes>
      </Router>
    </div>
  );
};

export default AllRoute;
