import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import { FaPhoneAlt } from "react-icons/fa";
import { Link } from "react-router-dom";

import img1 from "../../img/component_images/about_us_trust_scaled.jpg";

import "./style.css";

const Promo_2 = () => {
  const { t } = useTranslation();

  const onClickLinkTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>

      <section className="gauto-about-promo section_70">
        <Container>
          <Row>
            <Col md={12}>
              <div className="about-promo-text">
                <h3
                  dangerouslySetInnerHTML={{
                    __html: t("about_page.proud_title", {
                      interpolation: { escapeValue: false },
                    }),
                  }}
                ></h3>
                <Link to="/szolgaltatasaink" onClick={onClickLinkTop} className="gauto-btn">
                <p>Szolgáltatások</p>
                </Link>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="about-promo-image">
                <img src={img1} alt="about promo" />

              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Promo_2;
