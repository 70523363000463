import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

import Header from "../../components/header";
import PageTitle from "../../components/PageTitle";
import Career2 from "../../components/Career2";
import Footer from "../../components/Footer";
import MobileMenu from "../../components/MobileMenu";
import Separator from "../../components/Separator";
import Contact from "../../components/Contact";

const CareerPage2 = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <MobileMenu />
      <Header /> 
      <PageTitle
        pageTitle={t("header-navigation.career")}
        pagesub={t("header-navigation.career")}
      />
      <Career2 />
      <Contact />
      <Separator />
      <Footer />
    </Fragment>
  );
};
export default CareerPage2;
