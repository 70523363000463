import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

import Header from "../../components/header";
import PageTitle from "../../components/PageTitle";
import Career from "../../components/Career";
import Footer from "../../components/Footer";
import MobileMenu from "../../components/MobileMenu";
import Separator from "../../components/Separator";
import Contact from "../../components/Contact";

const CareerPage = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <MobileMenu />
      <Header /> 
      <PageTitle
        pageTitle={t("header-navigation.career")}
        pagesub={t("header-navigation.career")}
      />
      <Career />
      <Contact />
      <Separator />
      <Footer />
    </Fragment>
  );
};
export default CareerPage;
