import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { Col, Container, Row, Dropdown } from "react-bootstrap";
import {
  FaPhoneAlt,
  FaSignInAlt,
  FaUserAlt,
  FaSearch,
  FaGlobe,
  FaDiaspora,
  FaPhoneSquareAlt,
  FaMapMarkerAlt
} from "react-icons/fa";
import MobileMenu from "../../components/MobileMenu";

import Logo from "../../img/olasztruck_logo_upscaled_removedbg.png";
import globe from "../../img/globe.png";
import clock from "../../img/clock.png";
import "flag-icon-css/css/flag-icons.min.css";
import "./style.css";

const languages = [
  {
    code: "fr",
    name: "Français",
    country_code: "fr",
  },
  {
    code: "en",
    name: "English",
    country_code: "gb",
  },
  {
    code: "pt",
    name: "Português",
    country_code: "pt",
  },
];

const Header = () => {
  const SubmitHandler = (e) => {
    e.preventDefault();
  };

  const onClick = (e) => {
    e.preventDefault();
  };

  const { t } = useTranslation();

  const onClickLinkTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <Fragment>
      <section className="gauto-header-top-area">
        <Container>
          <Row>
            <Col md={6} className="header-top-left-col">
              <div className="header-top-left">
                <p className="header-phone-phar-left">
                  {t("need_help")} {/* <FaPhoneAlt /> */}{/*  {t("call")}  +321 123 45 978 */}
                </p>
                {/* <p>&nbsp;</p> */}
               
                
              </div>
            </Col>
            <Col md={6}> <p className="header-phone-phar-right">
              <span className="header-phone-numbers"><FaPhoneAlt />&nbsp;Autómentő (30) 953 4803</span>
              <span className="header-phone-numbers"><FaPhoneAlt />&nbsp;Autómentő (30) 655 6533</span>
              <span className="header-phone-numbers mobile-header-office-phone"><FaPhoneSquareAlt />&nbsp;Iroda (70) 944 0404</span></p>
              {/* <div className="header-top-right">
                <Link to="/login">
                  <FaSignInAlt />
                  {t("login")}
                </Link>
                <Link to="/register">
                  <FaUserAlt />
                  {t("register")}
                </Link>
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    <FaGlobe /> {t("language")}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {languages.map(({ code, name, country_code }) => (
                      <Dropdown.Item
                        eventKey={name}
                        key={country_code}
                        to="/"
                        onClick={() => i18next.changeLanguage(code)}
                      >
                        <span
                          className={`flag-icon flag-icon-${country_code}`}
                        ></span>{" "}
                        {name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div> */}
            </Col>
          </Row>
        </Container>
      </section>
      <header className="gauto-main-header-area">
        <Container>
          <Row className="custom-position-row">
            <Col md={5}>
              <div className="site-logo">
                <Link to="/kezdolap">
                  <img src={Logo} alt="gauto" />
                </Link>
              </div>
            </Col>
            <Col lg={4} sm={9} className="custom-position-col">
              <div className="header-promo">
                <div className="single-header-promo">
                  <div className="header-promo-icon">
                    {/* <img src={globe} alt="globe" /> */}
                    <FaMapMarkerAlt className="location-icon"/>
                  </div>
                  <div className="header-promo-info">
                    <h3>Magyarország, Tiszavasvári</h3>           
                    <p>{t("melbourne_city")}</p>
                  </div>
                </div>
                {/* <div className="single-header-promo">
                  <div className="header-promo-icon">
                    <img src={clock} alt="clock" />
                  </div>
                  <div className="header-promo-info">
                    <h3>Monday to Friday</h3>
                    <p>9:00am - 6:00pm</p>
                  </div>
                </div> */}
              </div>
            </Col>
            <div className="col-lg-3">
              <div className="header-phone-office"><span className="header-phone-numbers"><FaPhoneSquareAlt /> Iroda (70) 944 0404</span></div>
              <div className="header-action">
                <a href="tel:06709440404">
                  <FaDiaspora /> {t("request_call")}
                </a>
              </div>
            </div>
          </Row>
        </Container>
      </header>
      <section className="gauto-mainmenu-area">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="mainmenu">
                <nav>
                  <ul id="gauto_navigation">
                    <li>
                      <Link to="/">{t("header-navigation.home")}</Link>
                    </li>
                    <li>
                      <Link to="/kamionalvaz-egyengetes" onClick={onClickLinkTop}>
                        {t("header-navigation.service")}
                      </Link>
                      {/* <ul>
                        <li>
                          <Link to="/szolgaltatasaink">
                            {t("header-navigation.all_service")}
                          </Link>
                        </li>
                        <li>
                          <Link to="/kamionalvaz-egyengetes">
                            {t("header-navigation.service_details")}
                          </Link>
                        </li>
                      </ul> */}
                    </li>
                    <li>
                      <Link to="/galeria">
                        {t("header-navigation.gallery")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/karosszerialakatos">Karrier</Link>
                    </li>
                    <li>
                      <Link to="/rolunk">{t("header-navigation.about")}</Link>
                    </li>
                    <li>
                      <Link to="/kapcsolat">
                        {t("header-navigation.contact")}
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </Col>
            {/* <Col lg={3} sm={12}>
              <div className="main-search-right">
                <MobileMenu />
                <div className="header-cart-box">
                  <div className="login dropdown">
                    <Link to="/cart" className="cart-icon" id="dropdownMenu1">
                      <span>2</span>
                    </Link>
                  </div>
                </div>
                <div className="search-box">
                  <form onSubmit={SubmitHandler}>
                    <input type="search" placeholder="Search" />
                    <button type="submit">
                      <FaSearch />
                    </button>
                  </form>
                </div>
              </div>
            </Col> */}
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default Header;
