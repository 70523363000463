import React, { Fragment } from "react";
import Header from "../../components/header";
import Hero from "../../components/hero";
import FindCar from "../../components/findcar";
import About from "../../components/About";
import ServiceList from "../../components/ServiceList";
import Promo from "../../components/Promo";
import Promo_gallery from "../../components/Promo_gallery";
import Footer from "../../components/Footer";
import MobileMenu from "../../components/MobileMenu"
import Separator from "../../components/Separator";

const HomePage = () => {
  return (
    <Fragment>
      <MobileMenu />
      <Header />
      <Hero />
      <FindCar />
      <About />
      <ServiceList />
      <Promo />
      <Promo_gallery />
      <Separator />
      <Footer />
    </Fragment>
  );
};
export default HomePage;
