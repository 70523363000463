import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";

import img1 from "../../img/mini_icons/alvaz.png";
import img2 from "../../img/mini_icons/javitas.png";
import img3 from "../../img/mini_icons/tarolas.png";

import img4 from "../../img/mini_icons/alvaz.png";
import img5 from "../../img/mini_icons/javitas.png";
import img6 from "../../img/mini_icons/tarolas.png";

import "./style.css";

const ServiceList = () => {
  const { t } = useTranslation();

  const onClickLinkTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <section className="gauto-service-area service-page-area section_70 service-list-bg">
      <Container>
        <Row>
          <Col md={12}>
            <div className="site-heading">
              <h4>{t("see_our")}</h4>
              <h2>{t("latest_service")}</h2>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="single-service-col" md={3}>
            <div className="single-service">
              <span className="service-number">01 </span>
              <div className="service-icon">
              <Link to="/javitas" onClick={onClickLinkTop}>
                <img src={img1} alt="city trasport" />
              </Link>
              </div>
              <div className="service-text">
                <Link to="/javitas" onClick={onClickLinkTop}>
                  <h3>Kamionalváz egyengetés</h3>
                </Link>
                <p>
                  Sérült kamionok alvázegyengetése huzatópadon JOSAM technológiával
                </p>
              </div>
            </div>
          </Col>
          <Col className="single-service-col" md={3}>
            <div className="single-service">
              <span className="service-number">02 </span>
              <div className="service-icon">
              <Link to="/automentes" onClick={onClickLinkTop}>
                <img src={img2} alt="airport trasport" />
              </Link>
              </div>
              <div className="service-text">
                <Link to="/automentes" onClick={onClickLinkTop}>
                  <h3>Karosszéria javítás</h3>
                </Link>
                <p>Személy és tehergépjárművek karambolos javítása, fényezése</p>
              </div>
            </div>
          </Col>
          <Col className="single-service-col" md={3}>
            <div className="single-service">
              <span className="service-number">03 </span>
              <div className="service-icon">
              <Link to="/gepkocsi-tarolas" onClick={onClickLinkTop}>
                <img src={img3} alt="hospital trasport" />
              </Link>
              </div>
              <div className="service-text">
                <Link to="/gepkocsi-tarolas" onClick={onClickLinkTop}>
                  <h3>Autófényezés</h3>
                </Link>
                <p>
                  Autófényezés saját fényezőfülkében
                </p>
              </div>
            </div>
          </Col>
          <Col className="single-service-col" md={3}>
            <div className="single-service">
              <span className="service-number">04 </span>
              <div className="service-icon">
                <img src={img4} alt="wedding trasport" />
              </div>
              <div className="service-text">
                <Link to="/service-single">
                  <h3>Biztosítási ügyintézés</h3>
                </Link>
                <p>Teljeskörű biztosítási ügyintézés + allianz</p>
              </div>
            </div>
          </Col>
          <Col className="single-service-col" md={3}>
            <div className="single-service">
              <span className="service-number">05 </span>
              <div className="service-icon">
                <img src={img5} alt="wedding trasport" />
              </div>
              <div className="service-text">
                <Link to="/service-single">
                  <h3>Autómentés<br />0-24/7</h3>
                </Link>
                <p>Autómentés éjjel-nappal hívható</p>
                <p>(30) 953 4803</p>
                <p>(30) 655 6533</p>
              </div>
            </div>
          </Col>
          <Col className="single-service-col" md={3}>
            <div className="single-service">
              <span className="service-number">06 </span>
              <div className="service-icon">
                <img src={img6} alt="wedding trasport" />
              </div>
              <div className="service-text">
                <Link to="/service-single">
                  <h3>Személygépkocsi bérbeadás</h3>
                </Link>
                <p>Javítás időtartamára csereautót tudunk biztosítani térítés ellenében</p>
              </div>
            </div>
          </Col>
          <Col className="single-service-col" md={3}>
            <div className="single-service">
              <span className="service-number">07 </span>
              <div className="service-icon">
                <img src={img6} alt="wedding trasport" />
              </div>
              <div className="service-text">
                <Link to="/service-single">
                  <h3>Gépkocsi tárolás</h3>
                </Link>
                <p>Balesetes gépkocsik tárolása térítés ellenében</p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ServiceList;
