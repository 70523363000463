import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import { FaCheck } from "react-icons/fa";
import signature from "../../img/signature.png";
import Img1 from "../../img/component_images/about_us_01_scaled.jpg";
import "./style.css";
const About = () => {
  const { t } = useTranslation();
  return (
    <section className="gauto-about-area section_70">
      <Container>
        <Row>
          <Col lg={6}>
            <div className="about-left">
              <h4>{t("about_us_title")}</h4>
              <h2>{t("welcome_title")}</h2>
              <p>Szolgáltatásaink 35 éve gépjármű karosszéria javítással foglalkozik. Járműspecifikus szerszámokkal, saját fényezőfülkével rendelkezünk.</p>
              <div className="about-list">
                <ul>
                  <li>
                    <span>
                      <FaCheck />
                    </span>
                    Megbízhatóság- 35 éve a piacon
                  </li>
                  <li>
                    <span>
                      <FaCheck />
                    </span>
                    Tapasztalt, szakképzett szerelők
                  </li>
                  <li>
                    <span>
                      <FaCheck />
                    </span>
                    Teljeskörű biztosítási ügyintézés
                  </li>
                  <li>
                    <span>
                      <FaCheck />
                    </span>
                    Csereautó biztosítás
                  </li>
                </ul>
                <Row>
                  <Col lg={12}>
                    <ul>
                      <li>Hétfő: 8:00 – 17:00</li>
                      <li>Kedd: 8:00 – 17:00</li>
                      <li>Szerda: 8:00 – 17:00</li>
                      <li>Csütörtök: 8:00 – 17:00</li>
                      <li>Péntek: 8:00 – 17:00</li>
                      <li>Szombat: ZÁRVA</li>
                      <li>Vasárnap: ZÁRVA</li>
                    </ul>
                  </Col>
                </Row>
              </div>
              {/* <div className="about-signature">
                <div className="signature-left">
                  <img src={signature} alt="signature" />
                </div>
                <div className="signature-right">
                  <h3>Robertho Garcia</h3>
                  <p>{t("president")}</p>
                </div>
              </div> */}
            </div>
          </Col>
          <Col lg={6}>
            <div className="about-right">
              <img src={Img1} alt="car" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default About;
