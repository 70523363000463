import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap";

import "./style.css";

const Separator = () => {
    const { t } = useTranslation();
  
    const onClick = (e) => {
      e.preventDefault();
    };

    return (
        <section>
          <div className="ot-separator-white"></div>
          <div className="ot-separator-red"></div>
        </section>
    );
};

export default Separator;