import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";

import img1 from "../../img/component_images/gallery_img_01_scaled.jpg";
import img2 from "../../img/component_images/gallery_img_02_scaled.jpg";
import img3 from "../../img/component_images/gallery_img_03_scaled.jpg";
import img4 from "../../img/component_images/gallery_img_04_scaled.jpg";

import img1Full from "../../img/component_images/gallery_img_01.jpg";
import img2Full from "../../img/component_images/gallery_img_02.jpg";
import img3Full from "../../img/component_images/gallery_img_03.jpg";
import img4Full from "../../img/component_images/gallery_img_04.jpg";

import ReactFancyBox from 'react-fancybox/';
import 'react-fancybox/src/fancybox/fancybox.css';

import "./style.css";

const Team = () => {
  const { t } = useTranslation();

  const onClick = (e) => {
    e.preventDefault();
  };

  const onClickLinkTop = () => {
    window.scrollTo(0, 0);
  };


  return (
    <section className="gauto-driver-area section_70">
      <Container className="promo-gallery-width">
        <Row>
          <Col md={12}>
            <div className="site-heading">
              <h4>{t("experts")}</h4>
              <h2>{t("promo_gallery_title")}</h2>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={3} sm={6}>
          <ReactFancyBox
                thumbnail={img1}
                image={img1Full}/>
          </Col>
          <Col lg={3} sm={6}>
          <ReactFancyBox
                thumbnail={img2}
                image={img2Full}/>
          </Col>
          <Col lg={3} sm={6}>
          <ReactFancyBox
                thumbnail={img3}
                image={img3Full}/>
          </Col>
          <Col lg={3} sm={6}>
          <ReactFancyBox
                thumbnail={img4}
                image={img4Full}/>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className="load-more">
              <Link to="/galeria" onClick={onClickLinkTop}  className="gauto-btn">
                {t("promo_gallery_button")}
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Team;
