import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

import Header from "../../components/header";
import PageTitle from "../../components/PageTitle";
import ServiceDetailsPage2 from "../../components/ServiceDetails2";
import Footer from "../../components/Footer";
import MobileMenu from "../../components/MobileMenu";
import ServiceConditions from "../../components/ServiceCondtitions";
import Costs from "../../components/Costs";
import Separator from "../../components/Separator";

const ServiceSingle2 = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <MobileMenu />
      <Header />
      <PageTitle
        pageTitle={t("header-navigation.service_details")}
        pagesub={t("header-navigation.service_details")}
      />
      <ServiceDetailsPage2 />
      <Costs />
      <ServiceConditions />
      <Separator />
      <Footer />
    </Fragment>
  );
};
export default ServiceSingle2;
