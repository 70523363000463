import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container, Row, Col, Accordion } from "react-bootstrap";

import { FaFilePdf, FaRegFileWord, FaCheck } from "react-icons/fa";
import img1 from "../../img/career/szerelo_01_scaled.jpg";
import img2 from "../../img/career/szerelo_02_scaled.jpg";

import "./style.css";

const Career2 = () => {
  const { t } = useTranslation();

  const onClick = (e) => {
    e.preventDefault();
  };

  return (
    <section className="gauto-service-details-area section_70">
      <Container>
        <Row>
          <Col lg={4}>
            <div className="service-details-left">
              <div className="sidebar-widget">
                <ul className="service-menu">
                  <li>
                    <Link to="/karosszerialakatos">Karosszérialakatos</Link>
                  </li>
                  <li className="active">
                    <Link to="/szerelo">Szerelő</Link>
                  </li>
                </ul>

              </div>
            </div>
          </Col>
          <Col lg={8}>
            <div className="service-details-right">
              <h3>Szerelő</h3>
              <p>
                Dolor consectetur adipiscing elit sed do eiusmod tempor
                incididunt know you labore et dolore magna aliqua consectetur
                adipiscingLoren ipsum dolor conse ctetur sed adipi scing elit to
                sed do eiusmod tempor ipsum dolor consectetur adipiscing elit
                sed do eiu smod temp inci did know you labore et dolore magna
                aliqua consec consectetur.ergerg
              </p>
              <Row>
                <Col md={6} className="pad-right-sm">
                  <div className="service-details-image">
                    <img src={img1} alt="service details" />
                  </div>
                </Col>
                <Col md={6} className="pad-left-sm">
                  <div className="service-details-image">
                    <img src={img2} alt="service details" />
                  </div>
                </Col>
              </Row>
              <div className="service-details-list clearfix">
                <ul>
                  <li>
                    <FaCheck /> Ut ad minim veniam quis nostrud
                  </li>
                  <li>
                    <FaCheck /> Labore et dolore magna aliqua
                  </li>
                  <li>
                    <FaCheck /> Lorem ipsum dolor sit amet consectetur adipisicing
                  </li>
                  <li>
                    <FaCheck /> Ut ad minim veniam quis nostrud
                  </li>
                  <li>
                    <FaCheck /> Andomised words which don't look{" "}
                  </li>
                </ul>
                <ul>
                  <li>
                    <FaCheck /> Labore et dolore magna aliqua
                  </li>
                  <li>
                    <FaCheck /> Sed do eiusmod tempor incididunt
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Career2;
