import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";

import img1 from "../../img/mini_icons/alvaz.png";
import img2 from "../../img/mini_icons/javitas.png";
import img3 from "../../img/mini_icons/tarolas.png";
import img4 from "../../img/wedding-ceremony.png";
import img5 from "../../img/hotel-transport.png";
import img6 from "../../img/luggege-transport.png";

import "./style.css";

const ServiceList = () => {
  const { t } = useTranslation();

  const onClickLinkTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <section className="gauto-service-area service-page-area section_70">
      <Container>
        <Row>
          <Col md={12}>
            <div className="site-heading">
              <h4>{t("see_our")}</h4>
              <h2 className="cost-title">Áraink</h2>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={4} className="single-service-b-margin">
            <div className="single-service single-serivce-custom-h">
              <span className="service-number">01 </span>
              <div className="service-icon">
              {/* <Link to="/javitas" onClick={onClickLinkTop}> */}
                <img src={img1} alt="city trasport" />
              {/* </Link> */}
              </div>
              <div className="service-text">
                {/* <Link to="/javitas" onClick={onClickLinkTop}> */}
                  <h3>Javítási óradíjak</h3>
                {/* </Link> */}
                  <ul>
                    <li>Személyi gépkocsi: 20 500 Ft + ÁFA</li>
                    <li>Teher gépkocsi: 25 500 Ft + ÁFA</li>
                    <li>Kamion alváz javítás: 29 500 Ft + ÁFA</li>
                    <br /><br />
                  </ul>
              </div>
            </div>
          </Col>
          <Col md={4} className="single-service-b-margin">
            <div className="single-service single-serivce-custom-h">
              <span className="service-number">02 </span>
              <div className="service-icon">
              {/* <Link to="/automentes" onClick={onClickLinkTop}> */}
                <img src={img2} alt="airport trasport" />
              {/* </Link> */}
              </div>
              <div className="service-text">
                {/* <Link to="/automentes" onClick={onClickLinkTop}> */}
                  <h3>{t("air_transfer")}</h3>
                {/* </Link> */}
                  <ul>
                    <li>Alapdíj: 15 000 Ft</li>
                    <li>Szállítás 2000 Kg-ig: 350 Ft/Km</li>
                    <li>Szállítás 2000 Kg felett: 400 Ft/Km</li>
                    <li>Műszaki mentés: 8 500 Ft/óra</li>
                    <li>Éjszakai és ünnepi felár: 30%</li>
                  </ul>
              </div>
            </div>
          </Col>
          <Col md={4} className="single-service-b-margin">
            <div className="single-service single-serivce-custom-h">
              <span className="service-number">03 </span>
              <div className="service-icon">
              {/* <Link to="/gepkocsi-tarolas" onClick={onClickLinkTop}> */}
                <img src={img3} alt="hospital trasport" />
              {/* </Link> */}
              </div>
              <div className="service-text">
                {/* <Link to="/gepkocsi-tarolas" onClick={onClickLinkTop}> */}
                  <h3>{t("hospital_transfer")}</h3>
                {/* </Link> */}
                  <ul>
                    <li>Személyi gépkocsi: 1200 Ft + ÁFA</li>
                    <li>Teher gépkocsi: 2500 Ft + ÁFA</li>
                    <br /><br /><br />
                  </ul>
              </div>
            </div>
          </Col>
          {/* <Col md={4}>
            <div className="single-service">
              <span className="service-number">04 </span>
              <div className="service-icon">
                <img src={img4} alt="wedding trasport" />
              </div>
              <div className="service-text">
                <Link to="/service-single">
                  <h3>{t("wedding_ceremony")}</h3>
                </Link>
                <p>
                  Risus commodo maecenas accumsan lacus vel facilisis. Dorem
                  ipsum dolor consectetur adipiscing elit.
                </p>
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="single-service">
              <span className="service-number">05 </span>
              <div className="service-icon">
                <img src={img5} alt="wedding trasport" />
              </div>
              <div className="service-text">
                <Link to="/service-single">
                  <h3>{t("city_tour")}</h3>
                </Link>
                <p>
                  Risus commodo maecenas accumsan lacus vel facilisis. Dorem
                  ipsum dolor consectetur adipiscing elit.
                </p>
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="single-service">
              <span className="service-number">06 </span>
              <div className="service-icon">
                <img src={img6} alt="wedding trasport" />
              </div>
              <div className="service-text">
                <Link to="/service-single">
                  <h3> {t("baggage_transport")}</h3>
                </Link>
                <p>
                  Risus commodo maecenas accumsan lacus vel facilisis. Dorem
                  ipsum dolor consectetur adipiscing elit.
                </p>
              </div>
            </div>
          </Col> */}
        </Row>
      </Container>
    </section>
  );
};

export default ServiceList;
