import React from "react";
import axios from 'axios';
import { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import {
  FaFacebook,
  FaLinkedin,
  FaPaperPlane,
  FaPinterest,
  FaSkype,
  FaTwitter,
  FaVimeo,
} from "react-icons/fa";

import "./style.css";

const Contact = () => {
  const { t } = useTranslation();


  // API elérési útvonala
  const API_PATH = 'http://ot-build-api.test';

  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const subjectRef = useRef(null);
  const phoneRef = useRef(null);
  const textareaRef = useRef(null);
  
  // Form adatokat itt szedjük össze név alapján
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    phone: "",
    textarea: "",
    curURL : window.location.href,
    mailSent: false,
    error: null
  });

  /* const jsonData = JSON.stringify(formData); */


  

  const SubmitHandler = (e) => {
    e.preventDefault();
  };

  const onClick = (e) => {
    e.preventDefault();
  };



  // Email validáció ( még be kell állítani, hogy ha nem megfelelő formátumú az email, akkor jelenjen meg egy hiba üzenet) 
  /* Jelenleg valamiért bezavar a "submit-error" class-ba */
  const validateEmail = (email) => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}(\.[A-Za-z]+)*$/;
    return emailRegex.test(email);
  };


  const emailFieldCheck = () => {
    let emptyField = "";

    if (!validateEmail(formData.email)) { // Email validation
      emptyField = "email";
      let inputCheck = undefined;
      inputCheck = document.getElementById("contactEmail");
      inputCheck.classList.add("submit-error");
      return false;
    } else {
      emptyField = "";
      return true;
    }
  }




  // Üres mezők vizsgálása (submit gomb rákattintás után van használva)
  const emptyFieldCheck = () => {
    let emptyField = "";
    let inputCheck = undefined;

    function errorCheck(){
      if (formData.name === "") {
        emptyField = "name";
        inputCheck = document.getElementById("contactName");
      } else if (formData.email === "") {
        emptyField = "email";
        console.log("empty email");
        inputCheck = document.getElementById("contactEmail");
      } else if (formData.email !== "" && !emailFieldCheck()){
        emailFieldCheck();
        console.log("here")
      } else if (formData.subject === "") {
        emptyField = "subject";
        inputCheck = document.getElementById("contactSubject");
        console.log("should be here");
      } else if (formData.phone === "") {
        emptyField = "phone";
        inputCheck = document.getElementById("contactPhone");
      } else if (formData.textarea === "") {
        emptyField = "textarea";
        inputCheck = document.getElementById("contactTextarea");
      }
    }

    errorCheck();
  
    // Ha van üres mező, akkor arra rárakjuk a "submit-error" class-t
    if (emptyField !== "") {
      //console.log(`Please fill in the ${emptyField} field.`);
      
      inputCheck.classList.add("submit-error");
    }

  };




  /* const correctFieldCheck = () => {
    let correctField = "";
    let inputCheck = undefined;


    if (formData.name !== "") {
      correctField = "name";
      inputCheck = document.getElementById("contactName");
    } else if (formData.email !== "") {
      correctField = "email";
      inputCheck = document.getElementById("contactEmail");
    } else if (validateEmail(formData.email)) { // Email validation
      correctField = "email";
      inputCheck = document.getElementById("contactEmail");
    } else if (formData.subject !== "") {
      correctField = "subject";
      inputCheck = document.getElementById("contactSubject");
    } else if (formData.phone !== "") {
      correctField = "phone";
      inputCheck = document.getElementById("contactPhone");
    } else if (formData.textarea !== "") {
      correctField = "textarea";
      inputCheck = document.getElementById("contactTextarea");
    }
  
    if (correctField == "" && inputCheck != undefined) {
      
      inputCheck.classList.remove("submit-error");
    }
  }; */




  // Vizsgáljuk a formokon a value változást
  const handleChange = (e) => {
    setFormData((formData) => ({
      ...formData,
      [e.target.name]: e.target.value,
      
    }));
    //console.log(e.target.value);
    //console.log(e.target);

    // Ha üresen hagyunk egy mezőt, akkor rárakjuk a "submit-error" class-t
    if (e.target.value == "") {
      e.target.classList.add("submit-error");
    }

    // Ha nem hagyunk üresen egy mezőt, akkor levesszük a "submit-error" class-t
    if (e.target.value != "") {
      e.target.classList.remove("submit-error");
    }
  };


  /**
   * *  * * * * * * *   * * * * * * * *   * * * * * * * *   * * * * * * * * *
   *                                                                        *
   * Jól kitöltött mezőkre még rá kell rakni a "submit-correct" klasszt     *
   *                                                                        *
   * * *  * * * * * * *   * * * * * * * *   * * * * * * * *   * * * * * * * * 
   */









  /* useEffect(() => {
    const formInputs = document.querySelectorAll("form input, form textarea");

    const handleInputChange = (e) => {
      const fieldName = e.target.name;
      const fieldValue = e.target.value;

      setFormData((formData) => ({
        ...formData,
        [fieldName]: fieldValue,
      }));
    };

    formInputs.forEach((input) => {
      input.addEventListener("change", handleInputChange);
    });

    return () => {
      formInputs.forEach((input) => {
        input.removeEventListener("change", handleInputChange);
      });
    };
  }, []); */





  // Ajaxos postolás kezelése
  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your form submission logic here

   
    emptyFieldCheck();

    axios({
      method: 'post',
      url: `${API_PATH}`,
      /* headers: { 'Content-Type': 'application/json'}, */
      header: { 'Content-Type': 'application/x-www-form-urlencoded' },
      data: new URLSearchParams(formData)
    })
    
    .then(result => {
      setFormData((formData) => ({
        ...formData,
        mailSent: result.data.sent
      }));
    })
    .catch(error => {
      setFormData((formData) => ({
        ...formData,
        error: error.message
      }));
    });
  };

  return (
    
    <section className="gauto-contact-area section_70">
      <Container>
        <Row>
          <Col lg={7} md={6}>
            <div className="contact-left">
              <h3>{t("contact_page.get_touch")}</h3>
              <form onSubmit={SubmitHandler} method="POST">
                <Row>
                  <Col md={6}>
                    <div className="single-contact-field">
                      <input type="text"
                      id="contactName"
                      placeholder={t("contact_page.name")}
                      name="name"
                      ref = {nameRef}
                      value={formData.contactName}
                      onChange={handleChange}
                      className=""
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="single-contact-field">
                      <input
                        type="email"
                        placeholder={t("contact_page.email")}
                        id="contactEmail"
                        name="email"
                        ref = {emailRef}
                        value={formData.contactEmail}
                        onChange={handleChange}
                        className=""
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <div className="single-contact-field">
                      <input
                        type="text"
                        placeholder={t("contact_page.subject")}
                        id="contactSubject"
                        name="subject"
                        ref = {subjectRef}
                        value={formData.contactSubject}
                        onChange={handleChange}
                        className=""
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="single-contact-field">
                      <input type="tel"
                      placeholder={t("contact_page.phone")}
                      id="contactPhone"
                      name="phone"
                      ref = {phoneRef}
                      value={formData.contactPhone}
                      onChange={handleChange}
                      className=""
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <div className="single-contact-field">
                      <textarea
                        placeholder={t("contact_page.msg")}
                        defaultValue={""}
                        id="contactTextarea"
                        name="textarea"
                        ref = {textareaRef}
                        value={formData.contactPhone}
                        onChange={handleChange}
                        className=""
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <div className="single-contact-field">
                      <button onClick={handleSubmit} id="contact-submit" type="submit" className="gauto-theme-btn" name="submit">
                        <FaPaperPlane /> {t("contact_page.send")}
                      </button>
                    </div>
                  </Col>
                  <Col md={12}>
                    {/* <div>
                      {this.state.mailSent &&
                        <div>Thank you for contcting us.</div>
                      }
                    </div> */}
                  </Col>
                </Row>
              </form>
            </div>
          </Col>
          <Col lg={5} md={6}>
            <div className="contact-right">
              <h3>{t("contact_page.info_title")} </h3>
              <div className="contact-details">
                <p>
                  <i className="fa fa-map-marker" />Magyarország, 4440, Tiszavasvári, Polgári út. 2{" "}
                </p>
                <div className="single-contact-btn">
                  <h4>{t("contact_page.info_email")}</h4>
                  <a href="mailto:info@example.com">Info@olasztruck.hu</a>
                </div><br />
                <div className="single-contact-btn">
                  <h4>{t("contact_page.info_call")}</h4>
                  <h5>Autómentő </h5><a href="tel:06309534803">(30) 953 4803</a>
                </div>
                <div className="single-contact-btn">
                  <h5>Autómentő </h5>  <a href="tel:06306556533">(30) 655 6533</a>
                </div>
                <div className="single-contact-btn">
                  <h5>Iroda </h5>  <a href="tel:06306556533">(70) 944 0404</a>
                </div>
                <div className="social-links-contact">
                  <h4>{t("contact_page.info_follow")}</h4>
                  <ul>
                    <li>
                    <a href= "https://www.facebook.com/olasztruck/" target="_blank">
                        <FaFacebook />
                      </a>
                    </li>
                    {/* <li>
                      <Link to="/" onClick={onClick}>
                        <FaTwitter />
                      </Link> 
                    </li>
                    <li>
                      <Link to="/" onClick={onClick}>
                        <FaLinkedin />
                      </Link>
                    </li>
                    <li>
                      <Link to="/" onClick={onClick}>
                        <FaPinterest />
                      </Link>
                    </li>
                    <li>
                      <Link to="/" onClick={onClick}>
                        <FaSkype />
                      </Link>
                    </li>
                    <li>
                      <Link to="/" onClick={onClick}>
                        <FaVimeo />
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Contact;
