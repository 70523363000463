import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import { FaPhoneAlt } from "react-icons/fa";
import { Link } from "react-router-dom";

import "./style.css";

const ServiceConditions = () => {
  const { t } = useTranslation();

  const onClickLinkTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <section className="gauto-about-promo section_70 condition-section">
        <Container>
          <Row>
            <Col md={12}>
              <div className="about-promo-text condition-box">
                <h2 className="condition-title">TISZTELT MEGRENDELŐ!</h2>
                <h5 className="condition-subtitle">A JÁRMŰ JAVÍTÁSÁT AZ ALÁBBI FELTÉTELEK ALAPJÁN VÉGEZZÜK:</h5>
                <ul className="condition-unordered-list">
                  <li >A beépített anyag a számla kifizetéséig a javító tulajdona, a kész járművet a javítási összeg TELJES KIFIZETÉSE UTÁN tudjuk átadni.</li>
                  <li>AZ ÜGYFÉL ÁLTAL HOZOTT alkatrészt az alábbi feltételekkel tudjuk beszerelni:
                    <ul>
                      <li>A beépített alkatrészre NEM vállalunk garanciát.</li>
                      <li>A tévesen rendelt alkatrészt nem cseréljük, a szét- szedett autó tárolásáért külön díjat számítunk fel. </li>
                      <li>A vállalt óradíjból nem tudunk kedvezményt biztosítani.</li>
                    </ul>
                  </li>
                </ul>
                <h4 className="condition-subtitle">A JAVÍTÁS ELŐZETES MEGÁLLAPODÁS ALAPJÁN TÖRTÉNIK!</h4>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default ServiceConditions;
